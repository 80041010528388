import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import Layout from "../components/layout";
import * as styles from "./user-login.module.scss";

export default function UserLogin() {
    
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const login = () => {
      setLoading(true);
      setTimeout(() =>{
        setLoginError(true);
        setLoading(false);
      }, 600)
  };

  return (
    <Layout>
      <div className={styles.userLogin}>
        <Container>
          <div className={`card ` + styles.box}>
            <h1 className="mb-4">Login</h1>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            {loginError ? <div className="text-danger my-2">The username you entered doesn't belong to an account. Please check your username and try again.</div> : ""}
            <Button
              variant="primary"
              type="submit"
              className="mt-4"
              onClick={login}
              disabled={loading}
            >
                {
                    loading ? "Loading...": "Log In"
                }
            </Button>
          </div>
        </Container>
      </div>
    </Layout>
  );
}
